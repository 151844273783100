<template>
    <el-dialog :close-on-click-modal="false" title="新增配置分组" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">

        <el-form v-if="formShow" ref="form" label-width="80px">

            <el-form-item label="分组名称">
                <el-input v-model="name" />
            </el-form-item>

            <el-form-item label="序号">
                <el-input v-model="num" />
            </el-form-item>

        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            // value: {
            //   type: Object,
            //   required: true
            // }
        },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',

                name: "",
                num: "",

            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

        }
    }
</script>

<style>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>